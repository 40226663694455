import React, { useEffect } from "react";
import { Alert } from "reactstrap";
import { observer } from "mobx-react";
import { useIntl } from "gatsby-plugin-intl";

import alertStore from "../../store/alertStore";

const AppAlert = observer(() => {
    const intl = useIntl();
    const messages = intl.messages;
    const ifTranslationKeyExistAndInMessage = messages[`system.errorKeyToErrorMessage.${alertStore.translationKey}`];

    useEffect(() => {
        alertStore.updateAlertMessage({ 
            message : "",
            translationKey : "",
        });
        }, [])

    const onHandleDismissAlert = () => {
        alertStore.updateAlertMessage({ 
            message : "",
            translationKey : "",
        });
    }

    const displayErrorMessageHandler = () => {
        if (ifTranslationKeyExistAndInMessage){
            return messages[`system.errorKeyToErrorMessage.${alertStore.translationKey}`];
        }
        if (alertStore.message){
            return alertStore.message;
        }
    }

    if(!alertStore.message && !ifTranslationKeyExistAndInMessage) return null;
    
    return (
        <div className="app-alert">
            <Alert className="app-alert__banner" color="danger" fade={true} toggle={onHandleDismissAlert}>{ displayErrorMessageHandler() }</Alert>
        </div>
    );

});

export default AppAlert;
