import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import { observer } from "mobx-react";
import loaderStore from "../../store/loaderStore";

const AppCookieBanner = observer(() => {

    if(!loaderStore.showCookieBanner) return null;

    return (
        <div className="app-cookie-banner">
            <div className="app-cookie-banner__container container">
                <div className="app-cookie-banner__message">
                    <FormattedMessage id="cookie.banner.message" />
                </div>
                <div className="btn-container btn-container--center">
                    <button className="app-cookie-banner__cta btn btn-primary btn-lg" type="button" onClick={loaderStore.closeCookieBanner}>
                        <FormattedMessage id="cookie.banner.cta" />
                    </button>
                </div>
            </div>
        </div>
    );
});

export default AppCookieBanner;
