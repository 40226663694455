import React, { Fragment } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

const AppFooter = () => {
    const intl = useIntl();
    const messages = intl.messages;

    return (
        <Fragment>
            <footer className="app-footer">
                <div className="app-footer__container container">
                    <div className="app-footer__branding">
                        <div className="app-footer__branding-logo app-footer__branding-logo--bolttech"></div>
                    </div>
                </div>
                <div className="app-footer__container container">
                    <small className="app-footer__tnc">
                        <a href="https://broker.bolttech.tw/LocalFiles/Promotion/ECommerceRegulation.pdf" target="_blank" rel="noreferrer">保險經紀人公司經營電子商務自律規範</a>{" | "}
                        <a href="https://broker.bolttech.tw/LocalFiles/Promotion/WebsitePrivacy.pdf" target="_blank" rel="noreferrer">網站隠私權保護政策</a>{" | "}
                        <a href="https://broker.bolttech.tw/LocalFiles/Promotion/PersonalPrivacy.pdf" target="_blank" rel="noreferrer">個人資料保護政策</a>
                    </small>
                    <small className="app-footer__customer-service">
                        <FormattedMessage id="common.footer.customerService" values={{callCenterNumber: messages["common.callCenter.number"] }} />
                    </small>
                    <small className="app-footer__copyright">
                        &copy; {new Date().getFullYear()} bolttech 保特保經 版權所有
                    </small>
                </div>
            </footer>
        </Fragment>
    );
};

export default AppFooter;
