import React, { Fragment } from "react";
import { IntlContextConsumer, changeLocale, navigate } from "gatsby-plugin-intl";
import { observer } from "mobx-react";

import * as constants from "../../app.constant";

const AppHeader = props => {
    const { prevUrl } = props;

    const navigateBack = () => {
        if (prevUrl) {
            navigate(prevUrl);
        } else {
            window.history.back();
        }
    };

    return (
        <Fragment>
            <header className="app-header">
                <div className="app-header__container container">
                    {props.isBackBtn && (<button className="app-header__btn-back" onClick={navigateBack}>back</button>)}

                    {props.hasHeaderLogo &&
                        <Fragment>
                            <div className="app-header__logo app-header__logo--samsung-care"></div>
                        </Fragment>
                    }

                    {process.env.LANGUAGE_SWITCH_ENABLED === "true" && (
                        <div className="app-header__lang-switch">
                            <IntlContextConsumer>
                                {({ languages, language: currentLocale }) =>
                                    languages.map((language, index) => (
                                        <Fragment key={language}>
                                            <button className="app-header__lang-btn"
                                                disabled={currentLocale === language}
                                                key={language}
                                                type="button"
                                                onClick={() => changeLocale(language)}>
                                                {constants.LANGUAGE_NAME[language]}
                                            </button>
                                            {index !== (languages.length - 1) &&  " | "}
                                        </Fragment>
                                    ))
                                }
                            </IntlContextConsumer>
                        </div>
                    )}
                </div>
            </header>
        </Fragment>
    );
};

export default observer(AppHeader);
