import { decorate, observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class LoaderStore {
    isVisible = false;
    showCookieBanner = true;
    prevUrl = null;

    showLoader = isVisible => {
        this.isVisible = isVisible;
    };

    updateLocale = locale => {
        this.locale = locale;
    };

    closeCookieBanner = () => {
        this.showCookieBanner = false;
    };
}

decorate(LoaderStore, {
    isVisible: observable,
    locale: [persist, observable],
    showCookieBanner: [persist, observable],
    closeCookieBanner: action,
    showLoader: action
});

var loaderStore = new LoaderStore();
export default loaderStore;

if (typeof window !== "undefined") {
    const hydrate = create({
        storage: localStorage,
        jsonify: true,
    });
    hydrate("loaderStore", loaderStore);
}
