import { decorate, observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class AlertStore {
    message = "";
    translationKey = "";

    updateAlertMessage = (error) => {
        this.message = error.message;
        this.translationKey = error.translationKey;
    }
}

decorate(AlertStore, {
    message: [persist, observable],
    translationKey: [persist, observable],
    updateAlertMessage: action,
});

var alertStore = new AlertStore();

export default alertStore;

if (typeof window !== "undefined") {
    const hydrate = create({
        storage: localStorage,
        jsonify: true,
    });
    hydrate("alertStore", alertStore);
}
