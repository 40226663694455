/**
 * AppLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, Fragment } from "react";
import { StaticQuery, graphql } from "gatsby";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet-async";
import { Offline, Online } from "react-detect-offline";
import { useIntl } from "gatsby-plugin-intl";
import PropTypes from "prop-types";

import "../../assets/css/stylesheet.scss";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import AppAlert from "./AppAlert";
import AppLoadingSpinner from "./AppLoadingSpinner";
import AppNetworkDisconnected from "./AppNetworkDisconnected";
import AppCookieBanner from "./AppCookieBanner";
import faviconIco from "./../../assets/images/common/favicon.ico";
import faviconPng from "./../../assets/images/common/favicon.png";

let dataLayer;

if (typeof window !== "undefined") {
    dataLayer = window.dataLayer || [];
}

const AppLayout = observer(({ hasHeader, hasHeaderLogo, hasFooter, isBackBtn, prevUrl, children, isLoading }) => {

    const intl = useIntl();

    useEffect(() => {
        dataLayer.push({ event: "pageView" });
    });

    const getViewClass = hasHeader ? "app-view has-header" : "app-view";

    return (
        <Fragment>
            <Helmet>
                <html lang={intl.locale} />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <link rel="icon" href={faviconIco} type="image/x-icon" />
                <link rel="shortcut icon" href={faviconPng} type="image/x-icon" />
                <link rel="apple-touch-icon" href={faviconPng} />
                <script type="text/javascript" async={true}>
                    {`
                        /* Start VWO Async SmartCode */

                        window._vwo_code = window._vwo_code || (function(){
                        var account_id=${process.env.VWO_ACCOUNT_ID},
                        settings_tolerance=2000,
                        library_tolerance=2500,
                        use_existing_jquery=false,
                        is_spa=1,
                        hide_element='body',

                        /* DO NOT EDIT BELOW THIS LINE */
                        f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
                        window.settings_timer=setTimeout(function () {_vwo_code.finish() },settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());

                        /* End VWO Async SmartCode */
                    `}
                </script>
            </Helmet>

            <StaticQuery
                query={graphql`
                    query {
                        file(relativePath: { eq: "rel.txt" }) {
                            childPlainText {
                                content
                            }
                        }
                    }
                `}
                render={data => (
                    <div id="release-image-tag" style={{ width: 0, height: 0 }} hidden>{data.file.childPlainText.content.trim()}</div>
                )}>
            </StaticQuery>

            {isLoading && (<AppLoadingSpinner isVisible={isLoading}></AppLoadingSpinner>)}

            <Online polling={false}>
                <div className={getViewClass}>
                    <AppAlert />
                    {hasHeader && (<AppHeader hasHeaderLogo={hasHeaderLogo} isBackBtn={isBackBtn} prevUrl={prevUrl} />)}
                    <main className="app-main">{children}</main>
                    {hasFooter && (<AppFooter />)}
                </div>
            </Online>

            <Offline polling={false}>
                <div className={getViewClass}>
                    <AppHeader hasHeaderLogo={true} />
                    <AppNetworkDisconnected />
                </div>
            </Offline>

            <AppCookieBanner />
        </Fragment>
    );

});

AppLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AppLayout;
