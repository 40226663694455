import React from "react";
import networkErrorIcon from "../../assets/images/network_error_icon.svg";
import loaderStore from "../../store/loaderStore";

const AppNetworkDisconnected = () => {

    const pageReferesh = () => {
        loaderStore.showLoader(true);
        setTimeout(() => {
            loaderStore.showLoader(false);
        }, 1000);
    };

    return (
        <div className="app-network-disconnected">
            <div className="container">
                <div className="app-network-disconnected__header">
                    <h2 className="app-network-disconnected__status">
                        <img className="app-network-disconnected__status-icon img-fluid" src={networkErrorIcon} alt="" width="44" />
                        <span className="app-network-disconnected__status-text text-bold">You’re not connected to the internet</span>
                    </h2>
                    <p>Please check your internet connection or move closer to your router</p>
                </div>
                <p className="btn-container btn-container--center">
                    <button type="button" className="btn btn-lg btn-primary" onClick={() => pageReferesh()}>
                        TRY AGAIN
                    </button>
                </p>
            </div>
        </div>
    );

};

export default AppNetworkDisconnected;
